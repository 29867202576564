import { Injectable } from '@angular/core';
import { IWithdrawalHistoryDetailResponse } from '@merchant-portal/app/models/payment/withdrawal-history/withdrawal-history.interface';
import { Observable } from 'rxjs';

export interface WithdrawalHistoryDetailState {
  isLoading: boolean;
  currentWithdrawalHistory: IWithdrawalHistoryDetailResponse['data'] | null;
  downloadUrl: string | null;
  errorMessage: string | null;
}

@Injectable()
export abstract class WithdrawalHistoryDetailFactoryService {
  abstract vm$: Observable<WithdrawalHistoryDetailState>;
  abstract requestWithdrawalHistory(param: string): void;
}
