import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponentService } from '@merchant-portal/app/components/dialog/dialog.component.service';
import { UtilService } from '@merchant-portal/app/services/util.service';
import { ComponentStore } from '@ngrx/component-store';
import { MessageService } from 'primeng/api';
import { EMPTY, interval } from 'rxjs';
import { catchError, filter, switchMap, takeWhile, tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { OTP_EVENT_TYPE } from '../constant/event.type';
import {
  OtpParams,
  OtpState,
  OtpStoreFactory,
} from '../factory/otp-store.factory';
import { OtpApiService } from '../services/otp.api.service';

const initialState: OtpState = {
  errorMessage: '',
  isLoading: false,
  countdown: 30,
  isCountdownActive: true,
  isAccountBlocked: false,
  isSuspendAccount: false,
  isEventFromInvitation: false,
  isVeritifyOtpSuccess: false,
  otpToken: '',
};

@Injectable()
export class OtpStore
  extends ComponentStore<OtpState>
  implements OtpStoreFactory
{
  constructor(
    private otpApiService: OtpApiService,
    private authService: AuthService,
    private dialogComponentService: DialogComponentService,
    private router: Router,
    private utilService: UtilService,
    private messageService: MessageService,
  ) {
    super(initialState);
    this.select((state) => state.isAccountBlocked)
      .pipe(filter((isBlocked) => isBlocked))
      .subscribe(() => this.showBlockedDialog());
  }

  errorMessage$ = this.select((state) => state.errorMessage);
  isLoading$ = this.select((state) => state.isLoading);
  countdown$ = this.select((state) => state.countdown);
  isCountdownActive$ = this.select((state) => state.isCountdownActive);
  isAccountBlocked$ = this.select((state) => state.isAccountBlocked);
  isSuspendAccount$ = this.select((state) => state.isSuspendAccount);
  isEventFromInvitation$ = this.select((state) => state.isEventFromInvitation);

  vm$ = this.select({
    errorMessage: this.errorMessage$,
    isLoading: this.isLoading$,
    countdown: this.countdown$,
    isCountdownActive: this.isCountdownActive$,
    isAccountBlocked: this.isAccountBlocked$,
    isSuspendAccount: this.isSuspendAccount$,
    isEventFromInvitation: this.isEventFromInvitation$,
    isVeritifyOtpSuccess: this.select((state) => state.isVeritifyOtpSuccess),
    otpToken: this.select((state) => state.otpToken),
  });

  verifyOtp = this.effect<OtpParams>((otpParams$) =>
    otpParams$.pipe(
      tap((otp) => {
        if (otp.otp.length < 6) {
          this.patchState({
            errorMessage: '',
            isAccountBlocked: false,
            isVeritifyOtpSuccess: false,
          });
        }
      }),
      filter((otp) => otp.otp.length === 6),
      tap(() => this.patchState({ isLoading: true })),
      switchMap((otp) =>
        this.otpApiService
          .verifyOtp(otp.otp, this.authService.getTempToken() ?? '')
          .pipe(
            switchMap((res) => {
              this.patchState({
                errorMessage: '',
                isVeritifyOtpSuccess: true,
                isLoading: false,
              });
              this.authService.setTempToken(res.data.token);
              if (otp.event === 'first-time-login') {
                this.patchState({ isEventFromInvitation: true });
                return EMPTY;
              } else if (otp.event === OTP_EVENT_TYPE.USER_LOGIN) {
                return this.otpApiService
                  .successVerify(this.authService.getTempToken() || '')
                  .pipe(
                    tap((res) => {
                      this.authService.setUserInfo(res.data.userInfo);
                      this.authService.setAccessToken(res.data.accessToken);
                      this.authService.setRefreshToken(res.data.refreshToken);
                      this.checkAccessToken();
                    }),
                  );
              }
              return EMPTY;
            }),
            catchError(({ error }: HttpErrorResponse) => {
              this.patchState({ isLoading: false });
              if (error.errors.includes('blocked')) {
                this.patchState({ isAccountBlocked: true });
              } else if (error.errors.includes('expired')) {
                this.patchState({ errorMessage: 'OTP Expired' });
              } else {
                this.patchState({ errorMessage: error.errors });
              }
              return EMPTY;
            }),
          ),
      ),
    ),
  );

  resendOtp = this.effect<string>((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.patchState({ isLoading: true });
      }),
      switchMap((event) =>
        this.otpApiService
          .resendOtp({
            email: this.authService.getEmail() || '',
            event: event,
          })
          .pipe(
            tap((res) => {
              this.authService.setTempToken(res.data.token);
              this.patchState({
                errorMessage: '',
                isLoading: false,
                otpToken: res.data.token,
              });
              this.startCountdown();
            }),
            catchError(({ error }: HttpErrorResponse) => {
              this.patchState({ isLoading: false, otpToken: '' });
              if (error.errors.includes('suspended')) {
                const suspendedTime = this.utilService.parseSuspendedTime(
                  error.errors,
                );
                if (suspendedTime) {
                  const remainingMinutes =
                    this.utilService.getRemainingMinutes(suspendedTime);
                  const errorMessage = `You have attempted to send the code 3 times,<br> please try again in ${remainingMinutes} minutes.`;
                  this.patchState({
                    errorMessage: errorMessage,
                  });
                  this.showSuspendedToast(errorMessage);
                }
                this.patchState({ isSuspendAccount: true });
              } else {
                this.patchState({ errorMessage: error.errors });
              }
              return EMPTY;
            }),
          ),
      ),
    ),
  );

  showSuspendedToast(message: string) {
    this.messageService.add({
      key: 'message',
      data: {
        title: '',
        body: message,
      },
    });
  }

  showBlockedDialog() {
    this.dialogComponentService
      .openConfirmationDialog({
        width: '530px',
        height: '275px',
        closable: true,
        body: {
          title: 'Account Blocked',
          message: `You've entered too many incorrect codes. Please contact helpdesk for assistance.`,
          closeText: 'Close',
          confirmText: 'Contact Helpdesk',
          isDoNothing: true,
        },
      })
      .onClose.pipe(
        tap(() => {
          this.router.navigateByUrl('/login').then(() => {
            window.location.reload();
          });
        }),
      )
      .subscribe();
  }

  getEmail() {
    return this.authService.getEmail();
  }

  startCountdown() {
    this.patchState({ countdown: 30, isCountdownActive: true });
    interval(1000)
      .pipe(
        takeWhile(() => this.get().countdown > 0),
        tap(() => {
          this.patchState((state) => ({
            countdown: state.countdown - 1,
          }));
        }),
        tap(() => {
          if (this.get().countdown === 0) {
            this.patchState({ isCountdownActive: false });
          }
        }),
      )
      .subscribe();
  }

  checkAccessToken() {
    if (this.authService.getAccessToken()) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  resetState() {
    this.patchState({
      errorMessage: '',
      isSuspendAccount: false,
      isAccountBlocked: false,
    });
  }
}
