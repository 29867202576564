<div class="withdraw-dialog">
  <div class="title">
    <span>Withdrawal Request</span>
  </div>

  <div class="content-wrap active-balance">
    <span class="label">
      Active Balance
    </span>
    <span class="bold-label">
      {{content.availableBalance | defaultValue : 0
      | currency : "IDR" : "symbol" : "1.0-0" : "id"}}
    </span>
  </div>
  <div class="content-wrap">
    <label class="amount" for="amount">Withdraw Amount <span style="color: red">*</span></label>
    <div class="p-inputgroup" style="height: 36px;">
      <span class="p-inputgroup-addon">Rp</span>
      <input type="text" name="amount" placeholder="0" id="amount" autocomplete="off" [(ngModel)]="amount" pInputText
        currencyMask [options]="{
          prefix: '',
          thousands: '.',
          precision: 0,
          align: 'left'
        }" />
    </div>
    <p *ngIf="
 amount && (amount < minAmount)
  " class="error-message">
      <span class="icon alert"></span>
      Minimum amount is {{minAmount | currency: "IDR" : "symbol" : "1.0-0" : "id"}}
    </p>
  </div>
  <div class="content-wrap bank-account">
    <span class="label">Bank Account</span>
    <div class="content-flex">
      <div class="active-balance">
        <app-svg-loader url="wallet.svg"></app-svg-loader>
      </div>
      <div class="content-wrap">
        <span class="bold-label" style="font-size: 14px;">{{content.bankAccounts[0].beneficiaryAccountName}}</span>
        <span class="label">{{content.bankAccounts[0].beneficiaryBankName}} <i class="icon pi pi-circle-fill"></i>
          {{content.bankAccounts[0].beneficiaryAccountNo}}</span>
      </div>
    </div>
  </div>
  <div class="wrap-button">
    <button pButton class="pg-outline-button" type="button" label="Cancel" (click)="cancel()"></button>
    <button [disabled]="(amount && (amount < minAmount)) || !amount" pButton class="pg-button" type="button"
      label="Next" (click)="confirm()"></button>
  </div>
</div>