import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { CreatePinStoreFactory } from './factory/create-pin-store';

@Component({
  selector: 'app-create-pin',
  standalone: true,
  imports: [
    CommonModule,
    CodeInputModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './create-pin.component.html',
  styleUrls: ['./create-pin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePinComponent implements OnInit {
  isComparing = false;
  pinValue = '';
  reInputValue = '';
  @Output() pinCreated = new EventEmitter<string>();
  @Input() fromInvitation = false;
  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  private store = inject(CreatePinStoreFactory);

  error$ = this.store.error$;
  isSuccess$ = this.store.isSuccess$;

  ngOnInit(): void {
    this.isSuccess$.subscribe((success) => {
      if (success) {
        this.pinCreated.emit(success);
      }
    });
  }
  onCodeChanged($event: string) {
    if (this.isComparing) {
      this.pinValue = $event;
    } else {
      this.reInputValue = $event;
    }
  }

  next() {
    if (!this.isComparing) {
      this.codeInput.reset();
      this.codeInput.focusOnField(0);
      if (this.store.checkValidPin(this.reInputValue)) {
        this.isComparing = true;
      }
    } else {
      this.store.submitPin(
        this.pinValue,
        this.reInputValue,
        this.fromInvitation,
      );
    }
  }
}
