import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../core/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static accessToken: string;
  static refresh = false;

  private authService: AuthService = inject(AuthService);

  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes('/login') ||
      request.url.includes('otp') ||
      request.url.includes('/token') ||
      request.url.includes('validate-invitation') ||
      request.url.includes('users/activate') ||
      request.url.includes('otp/verify') ||
      request.url.includes('auth/reset-pin') ||
      request.url.includes('credentials/client-secrets') ||
      request.url.includes('auth/forgot-password') ||
      request.url.includes('auth/reset-password') ||
      request.url.includes('auth/refresh')
      // request.url.includes('auth/refresh')
    ) {
      return next.handle(request);
    }

    AuthInterceptor.accessToken = this.authService.getAccessToken() ?? '';
    request = this.addAuthorizationHeader(request, AuthInterceptor.accessToken);

    const loginTerminatedError =
      'login session is terminated due to login on another device';

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !AuthInterceptor.refresh) {
          if (err.error.message == loginTerminatedError) {
            this.authService.logout(false);
            this.router.navigate(['/logout'], {
              queryParams: { reason: 'session-terminated' },
            });
          }

          AuthInterceptor.refresh = true;
          return this.authService.refreshToken().pipe(
            switchMap((res: any) => {
              const { accessToken, refreshToken } = res.data;
              AuthInterceptor.accessToken = accessToken;
              this.authService.setAccessToken(accessToken);
              if (refreshToken) {
                this.authService.setRefreshToken(refreshToken);
              }
              this.setRefreshFalse();
              return next.handle(
                this.addAuthorizationHeader(request, accessToken),
              );
            }),
            catchError(() => {
              this.authService.logout();
              return throwError(() => err);
            }),
          );
        }
        this.setRefreshFalse();
        return throwError(() => err);
      }),
    );
  }

  private addAuthorizationHeader(
    request: HttpRequest<unknown>,
    token: string,
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private setRefreshFalse() {
    AuthInterceptor.refresh = false;
  }
}
