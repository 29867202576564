import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PinValidator } from '@merchant-portal/app/core/guard/pin.guard.validator';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CreatePinStoreFactory } from '../factory/create-pin-store';
import { pinValidationActions } from '../store/action';
import { createPinFeature } from '../store/reducers';

@Injectable()
export class CreatePinStoreService extends CreatePinStoreFactory {
  private store = inject(Store);
  override loading$: Observable<boolean> = this.store.select(
    createPinFeature.selectLoading,
  );
  override error$: Observable<string | null> = this.store.select(
    createPinFeature.selectError,
  );
  override isSuccess$: Observable<string | null> = this.store.select(
    createPinFeature.selectIsSuccess,
  );
  override submitPin(
    pin: string,
    reEnterPin: string,
    fromInvitation: boolean,
  ): void {
    if (pin !== reEnterPin) {
      this.store.dispatch(
        pinValidationActions.requestCreatePinValidationFailed({
          error: {
            errors: 'PIN does not match',
          },
        } as HttpErrorResponse),
      );
      return;
    }
    this.store.dispatch(
      pinValidationActions.requestPinValidation(
        pin,
        reEnterPin,
        fromInvitation,
      ),
    );
  }

  override checkValidPin(pin: string) {
    const validationError = PinValidator.isValidPin(pin);
    if (validationError) {
      this.store.dispatch(
        pinValidationActions.requestCreatePinValidationFailed({
          error: {
            errors: validationError,
          },
        } as HttpErrorResponse),
      );
      return false;
    } else {
      this.store.dispatch(pinValidationActions.requestInputValidationSuccess());
      return true;
    }
  }
}
