import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { OtpApiService } from '@merchant-portal/app/core/otp/services/otp.api.service';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './verify-change-password-dialog.component.html',
  styleUrls: ['./verify-change-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService],
})
export class VerifyChangePasswordDialogComponent {
  private fb: FormBuilder = inject(FormBuilder);
  private otpApiService = inject(OtpApiService);
  private authService = inject(AuthService);
  passwordForm = this.fb.group({
    password: ['', [Validators.required]],
  });
  private otpToken = '';
  private password = '';
  private newPassword = '';

  errorMessage = signal('');

  errorMapper: Record<string, string> = {
    'ERROR_REQUEST | ERROR_UNAUTHORIZED | invalid password':
      'Password is incorrect.',
    'ERROR_REQUEST | ERROR_DUPLICATE_CHECK | password has been used before':
      'Password has been used before',
  };

  error = computed(() => {
    return this.errorMapper[this.errorMessage()]
      ? this.errorMapper[this.errorMessage()]
      : this.errorMessage();
  });

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.otpToken = this.dialogConfig.data?.otpToken;
    this.password = this.dialogConfig.data?.password;
    this.newPassword = this.dialogConfig.data?.newPassword;
  }

  inputType = {
    text: 'text',
  };

  validateNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/; // Allow only numbers
    const inputChar = event.key;
  
    if (!pattern.test(inputChar)) {
      event.preventDefault(); // Prevent non-numeric characters
    }
  }

  onSubmitNewPassword() {
    this.errorMessage.set('');

    this.otpApiService
      .verifyOtp(this.passwordForm.get('password')?.value ?? '', this.otpToken)
      .pipe(
        catchError(({ error }: HttpErrorResponse) => {
          this.errorMessage.set(error.message);
          return of('');
        })
      )
      .subscribe((res) => {
        if (res) {
          this.authService
            .changePassword({
              newPassword: this.newPassword,
              password: this.password,
            })
            .pipe(
              catchError(({ error }: HttpErrorResponse) => {
                this.errorMessage.set(error.errors);
                return of('');
              })
            )
            .subscribe((authRes) => {
              if (authRes) {
                this.ref.close(true);
              }
            });
        }
      });
  }
}
