<span class="p-card-title">Check Email</span>
<span class="p-card-subtitle">A one-time password has been sent to your registered email address.
  Please check your inbox and enter the one-time password below to change your password. </span>
<form class="form-list" [formGroup]="passwordForm">
  <div class="form-input">
    <label for="re-enter-password"> One-Time Password </label>
    <span class="form-input">
      <input formControlName="password" id="one-time-password" [type]="inputType.text" pInputText maxlength="6"
        (keypress)="validateNumber($event)" />
    </span>
  </div>
  <div class="warning-message">
    <div *ngIf="error() !== ''">
      <i class="pi pi-exclamation-triangle"></i> {{ error() }}
    </div>
  </div>
  <button [disabled]="!passwordForm.get('password')?.value" type="submit" (click)="onSubmitNewPassword()"
    class="pg-button">
    Next
  </button>
</form>