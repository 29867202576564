import { createFeature, createReducer, on } from '@ngrx/store';
import { pinValidationActions } from './action';

export interface State {
  loading: boolean;
  pin: string | null;
  error: string | null;
  isFromInvitation: boolean;
  isSuccess: string | null;
}

const initialState: State = {
  loading: false,
  pin: null,
  error: null,
  isFromInvitation: false,
  isSuccess: null,
};

const reducer = createReducer(
  initialState,
  on(
    pinValidationActions.requestPinValidation,
    (state, { fromInvitation, pin }): State => ({
      ...state,
      loading: true,
      isSuccess: pin,
      isFromInvitation: fromInvitation,
    }),
  ),
  on(
    pinValidationActions.requestCreatePinValidationSuccess,
    (state): State => ({
      ...state,
      loading: false,
      isSuccess: null,
    }),
  ),
  on(
    pinValidationActions.requestInputValidationSuccess,
    (state): State => ({
      ...state,
      loading: false,
      isSuccess: null,
      error: null,
    }),
  ),
  on(
    pinValidationActions.requestCreatePinValidationFailed,
    (state, { error }): State => ({
      ...state,
      loading: false,
      isSuccess: null,
      error: error.error.errors || 'Something went wrong',
    }),
  ),
);

export const createPinFeature = createFeature({
  name: 'Create Pin',
  reducer: reducer,
});
