import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  inject,
} from '@angular/core';
import { TooltipComponent } from '@merchant-portal/app/components/tooltip/component/tooltip.component';
import { TooltipDirectiveDirective } from '@merchant-portal/app/components/tooltip/directive/tooltip-directive.directive';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';

@Directive({
  selector: '[userAccess], [userAccesses]',
  standalone: true,
  hostDirectives: [
    {
      directive: TooltipDirectiveDirective,
    },
  ],
})
export class UserAccessDirective
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input() userAccess: string = '';
  @Input() userAccesses?: string[] = [];

  userAccess$ = new BehaviorSubject(this.userAccess);
  userAccesses$ = new BehaviorSubject(this.userAccesses);

  private store = inject(Store);
  private tooltipDirective = inject(TooltipDirectiveDirective);
  private userAccessList = this.store.select(
    dashboardFeature.selectPermissionList,
  );

  destroy = new Subject<boolean | undefined>();

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cdf: ChangeDetectorRef,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['userAccess'].currentValue !== changes['userAccess'].previousValue
    ) {
      this.userAccess$.next(this.userAccess);
    }

    if (
      changes['userAccesses'] &&
      changes['userAccesses']?.currentValue !==
        changes['userAccesses']?.previousValue
    ) {
      this.userAccesses$.next(this.userAccesses);
    }
  }

  ngAfterViewInit() {
    combineLatest([this.userAccessList, this.userAccess$, this.userAccesses$])
      .pipe(takeUntil(this.destroy))
      .subscribe(([data, userAccess, userAccesses]) => {
        const componentInstance = this.tooltipDirective.componentRef?.instance;
        this.tooltipDirective.componentRef!.setInput(
          'tooltip',
          'You don’t have access to this action. Contact your admin to add this access to your account.',
        );

        if (!userAccess && !userAccesses?.length) {
          if (componentInstance) {
            this.tooltipDirective.componentRef?.setInput('disabled', true);
          }
          this.renderer.removeClass(this.el.nativeElement, 'disabled-element');
          this.cdf.detectChanges();
          return;
        }

        if (userAccess) {
          this.handleSingleUserAccess(data, componentInstance);
          return;
        }

        if (userAccesses?.length) {
          this.handleMultipleUserAccess(data, userAccesses, componentInstance);
          return;
        }
      });
  }

  private handleSingleUserAccess(
    data: string[] | null,
    componentInstance: TooltipComponent | undefined,
  ) {
    if (data?.includes(this.userAccess)) {
      if (componentInstance) {
        this.tooltipDirective.componentRef?.setInput('disabled', true);
      }
      this.renderer.removeClass(this.el.nativeElement, 'disabled-element');
      this.cdf.detectChanges();
      return;
    }

    if (componentInstance) {
      this.tooltipDirective.componentRef?.setInput('disabled', false);
    }
    this.renderer.addClass(this.el.nativeElement, 'disabled-element');
    this.cdf.detectChanges();
  }

  private handleMultipleUserAccess(
    data: string[] | null,
    userAccesses: string[],
    componentInstance: TooltipComponent | undefined,
  ) {
    const isUserAccesses = userAccesses.some((item) => data?.includes(item));

    if (isUserAccesses) {
      if (componentInstance) {
        this.tooltipDirective.componentRef?.setInput('disabled', true);
      }
      this.renderer.removeClass(this.el.nativeElement, 'disabled-element');
      this.cdf.detectChanges();
      return;
    }

    if (componentInstance) {
      this.tooltipDirective.componentRef?.setInput('disabled', false);
    }
    this.renderer.addClass(this.el.nativeElement, 'disabled-element');
    this.cdf.detectChanges();
  }
}
