<div class="navbar-content">
  <div class="row" style="gap: 12px; align-items: center">
    <app-svg-loader url="company.svg"></app-svg-loader>
    <div class="heading-large">{{ userData?.merchantName }}</div>
  </div>

  <div style="flex-grow: 1"></div>
  <div *ngIf="!hideHelpCenter" (click)="showHelpCenterDialog()" tabindex="0" (keydown.enter)="showHelpCenterDialog()"
    class="content-icon">
    <img class="custom-icon" [src]="'../../../assets/icon/headset.png'" alt="icon-headset" />
  </div>
  <div *ngIf="!hideNotification" (click)="openNotification()" tabindex="0" (keydown.enter)="openNotification()"
    class="content-icon">
    <img class="custom-icon" [src]="'../../../assets/icon/bell.png'" alt="icon-bell" />
    <ul *ngIf="isNotificationOpen" role="menu" aria-labelledby="single-button" class="dropdown-menu"
      style="right: 247px">
      <div style="padding: 6px 19px 21px 22px">
        <span style="font-size: 16px; font-weight: 700">Notification</span>
      </div>
      <li role="menuitem"><i class="pi pi-send"></i>Transaction Successfull</li>
    </ul>
  </div>
  <div class="content-icon">
    <span tabindex="0" (click)="settingPanel.toggle($event)" (keyup.enter)="settingPanel.toggle($event)">
      <img src="../../../assets/icon/subtract.svg" alt="icon" />
    </span>
    <p-overlayPanel #settingPanel>
      <span style="font-size: 16px; font-weight: 700">Settings</span>

      <div>
        <a userAccess="activity-logs.view" [routerLink]="['/activity-log']" class="menu-item">
          <img class="custom-icon-dropdown" [src]="'../../../assets/icon/bullet.svg'" alt="icon-logs" />Audit Logs
        </a>
      </div>


      <a class="menu-item" *ngIf="!hideMyBusiness">
        <img src="../../../assets/icon/bag-suitcase.svg" alt=" My Business" />
        My Business
      </a>

      <div>
        <a userAccess="team-members.view" class="menu-item" [routerLink]="['/setting/team-members']">
          <img src="../../../assets/icon/group-meeting-call.svg" alt="icon team member" />
          Team Members
        </a>
      </div>

      <div>
        <a userAccess="roles-and-permissions.view" class="menu-item" [routerLink]="['/setting/roles']">
          <span class="icon office-worker"></span>
          Roles and Permissions
        </a>
      </div>

      <div>
        <a userAccess="deposit-setting.edit" class="menu-item" [routerLink]="['/setting/deposit']">
          <span class="icon coin"></span>
          Deposit
        </a>
      </div>

      <div *ngIf="!hideDeveloperSetting">
        <a [userAccess]="'developer-setting.view'" class="menu-item"
          [routerLink]="['/setting/developer-setting/credentials']">
          <span class="icon developer-setting"></span>
          Developer Setting
        </a>
      </div>

      <div>
        <a class="menu-item" [routerLink]="['/setting/available-services']">
          <span class="icon services"></span>
          Services
        </a>
      </div>

    </p-overlayPanel>
  </div>
  <div class="user-role">
    <span style="font-size: 16px; font-weight: 700">{{ userData?.name }}</span>
    <span style="font-size: 14px; font-weight: 500; color: #929292">
      {{ userData?.role }}</span>
  </div>
  <div (click)="accountPanel.toggle($event)" tabindex="0" (keydown.enter)="accountPanel.toggle($event)"
    class="content-icon">
    <img class="custom-icon" [src]="'../../../assets/icon/user.png'" alt="icon-user" />
    <p-overlayPanel #accountPanel>
      <div>
        <span style="font-size: 16px; font-weight: 700">Account</span>
      </div>
      <a [routerLink]="['/account-setting']" routerLinkActive="router-link-active" role="menuitem" class="menu-item">
        <img class="custom-icon-dropdown" [src]="'../../../assets/icon/account-info.svg'" alt="icon-info" />
        <div>My Account</div>
      </a>
      <div style="cursor: pointer" (click)="logout()" tabindex="0" (keydown.enter)="logout()" role="menuitem"
        class="menu-item">
        <img class="custom-icon-dropdown" [src]="'../../../assets/icon/logout.svg'" alt="icon-logout" />
        <div>Logout</div>
      </div>
    </p-overlayPanel>
  </div>
</div>