import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss', '../auth.scss'],
})
export class LogoutComponent {
  sessionTerminated = true;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      this.sessionTerminated = params['reason'] === 'session-terminated';
    });
  }
}
