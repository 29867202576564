import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { passwordValidator } from '@merchant-portal/app/core/guard/password.guard.validator';
import { OTP_EVENT_TYPE } from '@merchant-portal/app/core/otp/constant/event.type';
import { OtpApiService } from '@merchant-portal/app/core/otp/services/otp.api.service';
import { IOtpResponse } from '@merchant-portal/app/models/otp/otp.interface';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService],
})
export class ChangePasswordDialogComponent {
  private fb: FormBuilder = inject(FormBuilder);
  private otpApiService = inject(OtpApiService);
  passwordForm = this.fb.group({
    reEnterPassword: ['', [Validators.required, passwordValidator()]],
    newPassword: ['', [Validators.required, passwordValidator()]],
  });
  private currentPassword = '';
  private email = '';

  errorMessage = signal('');

  errorMapper: Record<string, string> = {
    'ERROR_REQUEST | ERROR_UNAUTHORIZED | invalid password':
      'Password is incorrect.',
    'ERROR_REQUEST | ERROR_DUPLICATE_CHECK | password has been used before':
      'Password has been used before',
  };

  error = computed(() => {
    return this.errorMapper[this.errorMessage()]
      ? this.errorMapper[this.errorMessage()]
      : this.errorMessage();
  });

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.email = this.dialogConfig.data?.email;
    this.currentPassword = this.dialogConfig.data?.currentPassword;
    this.passwordForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.errorMessage.set('');
    });
  }

  inputType = {
    reEnterPassword: 'password',
    newPassword: 'password',
  };

  toggleInputType(type: keyof typeof this.inputType) {
    this.inputType[type] =
      this.inputType[type] === 'password' ? 'text' : 'password';
  }
  onSubmitNewPassword() {
    this.errorMessage.set('');
    if (
      this.passwordForm.get('reEnterPassword')?.value ===
      this.passwordForm.get('newPassword')?.value
    ) {
      this.otpApiService.resendOtp({
        email: this.email,
        event: OTP_EVENT_TYPE.CHANGE_PASSWORD,
      })
      .pipe(
        catchError(({ error }: HttpErrorResponse) => {
          this.errorMessage.set(error.message);
          return of('');
        })
      )
      .subscribe((res: string | IOtpResponse) => {
        if (typeof res !== 'string' && res.data) {
          this.ref.close([this.passwordForm.get('reEnterPassword')?.value, res.data.token]);
        } else {
          this.errorMessage.set("Invalid response");
        }
      });
    } else {
      this.errorMessage.set('Password do not match.');
    }
  }
}
