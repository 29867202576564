import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  IApprovalCountResponse,
  ITransactionApprovalParam,
} from '@merchant-portal/app/models/disbursement/approval/approval.interface';
import { IBulkTransactionRecordResponse } from '@merchant-portal/app/models/disbursement/approval/transaction-record.interface';
import {
  AvailableBalance,
  BalanceResponse,
} from '@merchant-portal/app/models/transaction-history/transaction-history.interface';
import {
  DisbursementInsightFilter,
  DisbursementResponse,
  IDisbursementListParam,
  IDisbursementListResponse,
  IPaymentResponse,
  ITopUpPayload,
  TopUpResponse,
} from 'projects/merchant-portal/src/app/models/disbursement/disbursement.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import {
  TopupSimulationRequest,
  TopupSimulationResponse,
} from '../top-up-simulation/factory/top-up.factory';

@Injectable({
  providedIn: 'root',
})
export class DisbursementService {
  private http = inject(HttpClient);
  url = environment.backend_portal_url;

  getDisbursementSummary(filter: Partial<DisbursementInsightFilter> = {}) {
    let params = new HttpParams();

    Object.keys(filter).forEach((key) => {
      const currentFilter: Record<string, any> = filter;
      if (currentFilter[key] instanceof Date) {
        const dateParam: Date = currentFilter[key];
        params = params.set(key, dateParam.toISOString());
      } else {
        if (currentFilter[key]) params = params.set(key, currentFilter[key]);
      }
    });

    const url = `${this.url + `api/v1/disbursements/dashboard`}`;
    return this.http.get<DisbursementResponse>(url, { params });
  }

  getPaymentMethods(category: string) {
    const url = `${this.url + `api/v1/payment-methods`}/${category}`;
    return this.http.get<IPaymentResponse>(url);
  }

  disbursementTopUp(body: ITopUpPayload) {
    return this.http.post<TopUpResponse>(
      this.url + `api/v1/disbursements/top-up`,
      body,
    );
  }

  topUpSimulation(body: TopupSimulationRequest) {
    return this.http.post<TopupSimulationResponse>(
      this.url + `api/v1/disbursements/top-up-simulation-va`,
      body,
    );
  }

  disbursementList(body: Partial<IDisbursementListParam>) {
    return this.http.get<IDisbursementListResponse>(
      this.url + 'api/v1/disbursements',
      {
        params: new HttpParams({ fromObject: body as any }),
      },
    );
  }

  disbursementBulkList(body: Partial<IDisbursementListParam>) {
    return this.http.get<IBulkTransactionRecordResponse>(
      this.url + 'api/v1/disbursements/bulk/list',
      {
        params: new HttpParams({ fromObject: body as any }),
      },
    );
  }

  disbursementBulkRetryList(body: Partial<IDisbursementListParam>) {
    return this.http.get<IDisbursementListResponse>(
      this.url + 'api/v1/disbursements/bulk/list',
      {
        params: new HttpParams({ fromObject: body as any }),
      },
    );
  }

  sendApprovalActions(param: ITransactionApprovalParam) {
    return this.http.post(
      this.url + 'api/v1/disbursements/approval-actions',
      {
        bulkId: param.bulkId,
        approve: param.approve,
        reject: param.reject,
      },
      {
        headers: {
          'X-Idempotent-Key': param.bulkId?.toString() ?? '',
          'X-Request-PIN': window.btoa(param.pin ?? ''),
        },
      },
    );
  }
  retryDisbursementTransaction(transaction: { id: string }) {
    return this.http.post(this.url + 'api/v1/disbursements/single/retry', {
      id: transaction.id,
    });
  }

  retryDisbursementBulkTransaction(transaction: { id: string }) {
    return this.http.post(this.url + 'api/v1/disbursements/bulk/retry', {
      id: transaction.id,
    });
  }

  approvalCount(): Observable<IApprovalCountResponse> {
    return this.http.get<IApprovalCountResponse>(
      this.url + 'api/v1/disbursements/approval-dashboard',
    );
  }

  getTotalBalance(
    balanceParams: AvailableBalance,
  ): Observable<BalanceResponse> {
    const params = new HttpParams().set('usecase', balanceParams.usecase);
    return this.http.get<BalanceResponse>(`${this.url}/api/v1/balances`, {
      params,
    });
  }
}
