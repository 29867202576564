export class PinValidator {
  static isValidPin(pin: string): string | null {
    const ERR_IDENTICAL_PIN = 'PIN cannot consist of identical digits.';
    const ERR_SEQUENTIAL_PIN = 'PIN cannot be sequential.';

    // Check for identical digits (e.g., "111111")
    let isIdentical = true;
    for (let i = 1; i < pin.length; i++) {
      if (pin[i] !== pin[i - 1]) {
        isIdentical = false;
        break;
      }
    }
    if (isIdentical) {
      return ERR_IDENTICAL_PIN;
    }

    // Check for sequential digits (increasing: e.g., "123456" or decreasing: e.g., "654321")
    let isIncreasingSequential = true;
    let isDecreasingSequential = true;
    for (let i = 1; i < pin.length; i++) {
      if (parseInt(pin[i]) !== parseInt(pin[i - 1]) + 1) {
        isIncreasingSequential = false;
      }
      if (parseInt(pin[i]) !== parseInt(pin[i - 1]) - 1) {
        isDecreasingSequential = false;
      }
    }
    if (isIncreasingSequential || isDecreasingSequential) {
      return ERR_SEQUENTIAL_PIN;
    }

    return null;
  }
}
