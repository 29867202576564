import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  providers: [DialogService],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class ConfirmationDialogComponent {
  title = '';
  body = '';
  closeText = 'Close';
  confirmText = 'Confirm';
  isDoNothing = false;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.title = this.dialogConfig.data?.title;
    this.body = this.dialogConfig.data?.message;
    this.closeText = this.dialogConfig.data?.closeText ?? 'Close';
    this.confirmText = this.dialogConfig.data?.confirmText ?? 'Confirm';
    this.isDoNothing = this.dialogConfig.data?.isDoNothing;
  }

  close() {
    this.ref.close();
  }

  confirm() {
    if (!this.isDoNothing) {
      this.ref.close(true);
    }
  }
}
