import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const pinValidationActions = createActionGroup({
  source: 'Create Pin',
  events: {
    requestPinValidation: (
      pin: string,
      reInputPin: string,
      fromInvitation: boolean,
    ) => ({ pin, reInputPin, fromInvitation }),
    requestCreatePinValidationFailed: (error: HttpErrorResponse) => ({ error }),
    requestCreatePinValidationSuccess: () => emptyProps(),
    requestInputValidationSuccess: () => emptyProps(),
  },
});
