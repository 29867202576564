<div class="transaction-dialog" *ngIf="vm$ | async as vm">
  <div class="content-wrap">
    <div class="bottom-container">
      <div class="bottom-div">
        <div class="bottom-item">
          <span>Created Date</span>
          <span class="black-item">{{
            vm.currentWithdrawalHistory?.createdAt | date : 'dd MMM YYYY, hh:mm a'
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Last Update</span>
          <span class="black-item">{{
            vm.currentWithdrawalHistory?.updatedAt | date : 'dd MMM YYYY, hh:mm a'
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Created By</span>
          <span class="black-item">{{
            vm.currentWithdrawalHistory?.createdBy
            }}</span>
        </div>
        <div class="bottom-item">
          <span style="font-weight: 700;">Amount</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentWithdrawalHistory?.amount
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Transaction Status</span>
          <span [ngClass]="
              'status' + vm.currentWithdrawalHistory?.status
            ">{{ vm.currentWithdrawalHistory?.status || '' | paymentStatusTransform}}</span>
        </div>
        <div class="bottom-item">
          <span>Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentWithdrawalHistory?.id
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Bank Reference</span>
          <span class="black-item">{{
            vm.currentWithdrawalHistory?.bankReferenceNo ? vm.currentWithdrawalHistory?.bankReferenceNo : '-'
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Bank Name</span>
          <span class="black-item">{{
            vm.currentWithdrawalHistory?.beneficiaryBankName
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Account Number</span>
          <span class="black-item">{{
            vm.currentWithdrawalHistory?.beneficiaryAccountNo
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Beneficiary</span>
          <span class="black-item">{{
            vm.currentWithdrawalHistory?.beneficiaryAccountName
            }}</span>
        </div>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" class="pg-button">Close</button>
    </div>
  </div>
  <app-block-loading [show]="vm.isLoading"></app-block-loading>
</div>