<div class="success-dialog">
  <div>
    <div class="title-wrap">
      <h2>{{ title }}</h2>
      <span class="subTitle" [innerHTML]="body"></span>
    </div>
    <div class="wrap-button">
      <button (click)="close()" class="pg-button invert-button">
        {{ closeText }}
      </button>
      <button *ngIf="confirmText !== 'Contact CS'" (click)="confirm()" class="pg-button">{{ confirmText }}</button>
      <a style="text-decoration: none;" *ngIf="confirmText === 'Contact CS'" href="mailto:helpdesk@harsya.com"
        class="pg-button">{{ confirmText }}</a>
    </div>
  </div>
</div>