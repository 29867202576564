import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { Observable } from 'rxjs';
import { DefaultValuePipe } from '../../../../../../pipe/default-value.pipe';
import { PaymentStatusTransformPipe } from '../../pipe/payment-status-transform.pipe';
import {
  WithdrawalHistoryDetailFactoryService,
  WithdrawalHistoryDetailState,
} from './factory/withdrawal-history-detail-store-factory';
import { WithdrawalHistoryDetailStore } from './store/withdrawal-history-detail.store';
registerLocaleData(localeFr, 'id');
@Component({
  selector: 'app-withdrawal-history-detail',
  templateUrl: './withdrawal-history-detail.component.html',
  styleUrls: ['./withdrawal-history-detail.component.scss'],
  standalone: true,
  providers: [
    {
      provide: WithdrawalHistoryDetailFactoryService,
      useClass: WithdrawalHistoryDetailStore,
    },
  ],
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    BlockLoadingComponent,
    DialogModule,
    ToastModule,
    BlockLoadingComponent,
    PaymentStatusTransformPipe,
    DefaultValuePipe,
  ],
})
export class WithdrawalHistoryDetailComponent implements OnInit {
  id = '';
  vm$: Observable<WithdrawalHistoryDetailState>;
  showTooltip = false;

  constructor(
    private store: WithdrawalHistoryDetailFactoryService,
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.vm$ = this.store.vm$;
    this.id = this.dialogConfig.data?.id;
  }

  ngOnInit() {
    this.store.requestWithdrawalHistory(this.id);
    this.vm$.subscribe((data) => {
      if (data.errorMessage) {
        this.ref.close();
      }
    });
  }

  cancel() {
    this.ref.close();
  }
}
