import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWithdrawalHistoryDetailResponse } from '@merchant-portal/app/models/payment/withdrawal-history/withdrawal-history.interface';
import { environment } from '@merchant-portal/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WithdrawalHistoryDetailApiService {
  public backend_portal_url =
    environment.backend_portal_url + 'api/v1/withdrawals/';

  constructor(private http: HttpClient) {}

  getWithdrawalHistoryDetail(
    id: string,
  ): Observable<IWithdrawalHistoryDetailResponse> {
    return this.http.get<IWithdrawalHistoryDetailResponse>(
      this.backend_portal_url + id,
    );
  }
}
