import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentMethodTransform',
  standalone: true,
})
export class PaymentMethodTransform implements PipeTransform {
  transform(value: string | null, subMethod: string | null): string {
    let method = '';
    if (!value) {
      return '';
    }

    switch (value) {
      case 'QRIS':
        method = 'QRIS MPM';
        break;
      case 'VIRTUAL_ACCOUNT':
        method = 'Virtual Account';
        break;
      case 'CREDIT_CARD':
        method = 'Cards';
        break;
    }

    switch (subMethod) {
      case 'DYNAMIC':
        return `${method} (Dynamic)`;
      case 'STATIC':
        return `${method} (Static)`;
      case 'OPEN_STATIC':
        return `${method} (Open Static)`;
      case 'CLOSED_STATIC':
        return `${method} (Closed Static)`;
      case 'CLOSED_DYNAMIC':
        return `${method} (Close Dynamic)`;
    }

    return method;
  }
}
