<div class="wrap-container">
  <div class="page-subtitle">
    <span class="pending-balance" style="width: 50%;">
      Payout Balance<app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'" tooltipPosition="bottom"
        [pTooltip]="tooltipContentBalance" url="helper.svg"></app-svg-loader>
      <ng-template #tooltipContentBalance>
        <div>
          <span class="tooltip-text"> The number of total balance.</span>
        </div>
      </ng-template>
    </span>
  </div>
  <div class="item-top balance flex-grow-2">
    <div class="pending-balance">
      <span class="header-content"><i style="margin-top: -4px;" class="pi pi-wallet"></i> Active Balance
      </span>
    </div>

    <div class="header-flex" style="margin-bottom: 12px;">
      <div class="main-content top-content">
        <div>
          {{
          disbursementBalance?.availableBalance?.value
          | currency : "IDR" : "symbol" : "1.0-0" : "id"
          }}
        </div>
      </div>
      <button [userAccess]="'disbursement.top-up.create'" pButton [disabled]="!paymentMethodList"
        (click)="showTopUpDialog()" class="pg-button" style="margin-top: 0px">
        Top Up
      </button>
    </div>


    <hr width="100%" style="border: 1px solid #E4E4E4;" />
    <div class="pending-balance-wrapper">
      <div class="pending-balance" style="margin-top: 12px">
        <span class="header-content"> Pending Balance</span><app-svg-loader class="svg-icon"
          [tooltipStyleClass]="'tooltipApproval'" tooltipPosition="bottom" [pTooltip]="tooltipContentPending"
          url="helper.svg"></app-svg-loader>
      </div>

      <ng-template #tooltipContentPending>
        <div>
          <span class="tooltip-text"> Balance used by transactions that are still being processed</span>
        </div>
      </ng-template>
      <span class="header-content" style="color: rgba(243, 166, 71, 1); margin-top: 12px;">
        {{disbursementBalance?.pendingBalance?.value |
        currency : "IDR" : "symbol" : "1.0-0"
        : "id"}}</span>
    </div>

  </div>

  <div class="page-subtitle" style="margin-top: 2px">
    <span class="pending-balance" style="width:50%;">
      Waiting for Approval<app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'"
        tooltipPosition="bottom" [pTooltip]="tooltipContentApproval" url="helper.svg"></app-svg-loader>
      <ng-template #tooltipContentApproval>
        <div>
          <span class="tooltip-text"> The number of payout that are waiting for approval.</span>
        </div>
      </ng-template>
    </span>

    <span class="pending-balance">
      Waiting for Top Up<app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'"
        tooltipPosition="bottom" [pTooltip]="tooltipContentWaiting" url="helper.svg"></app-svg-loader>
      <ng-template #tooltipContentWaiting>
        <div>
          <span class="tooltip-text"> The number of payout that could not be processed due to insufficient funds.</span>
        </div>
      </ng-template>
    </span>
  </div>

  <app-disbursement-info-card permission="disbursement.disbursement-history.view" count="0" title="Single Transaction"
    [href]="totalWaitingForApprovalSingleTodayLink" [ammount]="
      disbursementSummary?.waitingTodaySingleTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " [count]="disbursementSummary?.waitingTodaySingleTransaction?.count?.toString()" status="waiting">
  </app-disbursement-info-card>

  <app-disbursement-info-card class="flex-grow-1" permission="disbursement.disbursement-history.view"
    title="Bulk Transaction" [count]="disbursementSummary?.waitingTodayBulkTransaction?.count?.toString()"
    [href]="totalWaitingForApprovalBulkTodayLink" [ammount]="
      disbursementSummary?.waitingTodayBulkTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="waiting">
  </app-disbursement-info-card>

  <app-disbursement-info-card permission="disbursement.disbursement-approval.view" class="flex-grow-1"
    title="Single Transaction" [href]="topUpSingleLink"
    [count]="disbursementSummary?.waitingForTopUpTodaySingleTransaction?.count?.toString()" [ammount]="
    disbursementSummary?.waitingForTopUpTodaySingleTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="waiting">
  </app-disbursement-info-card>

  <app-disbursement-info-card permission="disbursement.disbursement-approval.view" class="flex-grow-1"
    title="Bulk Transaction" [href]="topUpBulkLink" [ammount]="
    disbursementSummary?.waitingForTopUpTodayBulkTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " [count]="disbursementSummary?.waitingForTopUpTodayBulkTransaction?.count?.toString()" status="waiting">
  </app-disbursement-info-card>

  <div style="grid-column: 1 / -1;">
    <div class="p-input-icon-right date-filter">
      <div class="title-wrap">
        <i class="pi pi-sliders-h"></i>
        <span>Filter By Date</span>
      </div>
      <p-calendar [(ngModel)]="dateFilter" (ngModelChange)="onFilterDateChange($event)" [showIcon]="true"
        placeholder="Date" selectionMode="range" [minDate]="minDate" [maxDate]="maxDate">
      </p-calendar>
    </div>

    <span style="margin-top:8px" class="page-subtitle">Payout Insight <app-svg-loader class="svg-icon"
        [tooltipStyleClass]="'tooltipApproval'" tooltipPosition="bottom" [pTooltip]="tooltipContentPayoutInsight"
        url="helper.svg"></app-svg-loader></span>
    <ng-template #tooltipContentPayoutInsight>
      <div>
        <span class="tooltip-text"> The number of rejected or approved payout that are pending, successful, or
          failed.</span>
      </div>
    </ng-template>
  </div>

  <app-disbursement-info-card title="Total Payout" permission="disbursement.disbursement-history.view"
    [href]="redirectPage('allTransaction')" [count]=" getTransactionSummary('allTransaction').count" [ammount]="
      getTransactionSummary('allTransaction').sum 
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    ">
  </app-disbursement-info-card>

  <app-disbursement-info-card title="Pending" permission="disbursement.disbursement-history.view"
    [href]="redirectPage('totalPending')" [count]="getTransactionSummary('pendingTransaction').count" [ammount]="
      getTransactionSummary('pendingTransaction').sum
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    ">
  </app-disbursement-info-card>

  <app-disbursement-info-card class="flex-grow-1" title="Success" permission="disbursement.disbursement-history.view"
    [href]="redirectPage('totalSuccess')" [count]="getTransactionSummary('successTransaction').count" [ammount]="
      getTransactionSummary('successTransaction').sum
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="success">
  </app-disbursement-info-card>

  <app-disbursement-info-card class="flex-grow-1" title="Failed" permission="disbursement.disbursement-history.view"
    [href]="redirectPage('totalFailed')" [count]="getTransactionSummary('failedTransaction').count" [ammount]="
      getTransactionSummary('failedTransaction').sum
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="failed">
  </app-disbursement-info-card>

  <app-disbursement-info-card class="flex-grow-1" permission="disbursement.disbursement-history.view" title="Approved"
    [count]="getTransactionSummary('approvedTransaction').count" [href]="redirectPage('totalApproved')" [ammount]="
      getTransactionSummary('approvedTransaction').sum
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    ">
  </app-disbursement-info-card>

  <app-disbursement-info-card title="Rejected" permission="disbursement.disbursement-history.view"
    [href]="redirectPage('totalRejected')" [count]="getTransactionSummary('rejectedTransaction').count" [ammount]="
    getTransactionSummary('rejectedTransaction').sum
      | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
  ">
  </app-disbursement-info-card>
</div>