import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IWithdrawalPrepareResponse } from '@merchant-portal/app/models/payment/payment.interface';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ButtonComponent } from '../../../../../../../../ui-component/src/lib/button/button.component';
import { SvgLoaderModule } from '../../../../../components/icon/svg-loader/svg-loader/svg-loader.module';
import { DefaultValuePipe } from '../../../../../pipe/default-value.pipe';
registerLocaleData(localeFr, 'id');
@Component({
  selector: 'app-withdrawal-request-component-dialog',
  templateUrl: './withdrawal-request.component-dialog.html',
  styleUrls: ['./withdrawal-request.component-dialog.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    ToastModule,
    FormsModule,
    SvgLoaderModule,
    ButtonComponent,
    NgxCurrencyDirective,
    DefaultValuePipe,
  ],
})
export class WithdrawalRequestDialogComponent {
  amount: number | null = null;
  minAmount: number = 10000;
  showTooltip = false;
  content: IWithdrawalPrepareResponse['data'];

  constructor(
    private ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {
    this.content = this.dialogConfig.data;
  }

  confirm() {
    this.ref.close(this.amount);
  }

  cancel() {
    this.ref.close();
  }
}
