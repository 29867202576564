<body>
  <img class="img-logo" [src]="'../../../assets/images/iki.png'" alt="" />
  <div class="session">
    <form class="logout" autocomplete="off">
      <h4>You’ve been logged out</h4>
      <span *ngIf="!sessionTerminated"> See you next time</span>
      <span *ngIf="sessionTerminated"> You've been logged in on another device, so we've logged you out
        automatically.</span>
      <button [routerLink]="['/login']" routerLinkActive="router-link-active" type="submit">Close</button>
    </form>
  </div>
</body>